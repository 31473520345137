import { cn } from '@/utils/styles';

export default function Error({
  children,
  visible,
  className,
}: {
  children: React.ReactNode;
  visible: boolean;
  className?: string;
}) {
  return (
    visible && (
      <div className={cn('text-xs text-destructive', className)}>
        {children}
      </div>
    )
  );
}
