import Image from 'next/image';
import { Button } from '@/components/shadcn/button';

interface Props {
  onClick: () => void;
}

export default function BreezeLoginButton({ onClick }: Props) {
  return (
    <Button
      variant="secondary"
      size="fw"
      className="w-full justify-start gap-2 text-xs sm:text-base"
      onClick={onClick}>
      <Image
        src="/images/logos/breeze.svg"
        width={16}
        height={14}
        alt="Phantom logo"
      />
      Continue with Breeze
    </Button>
  );
}
