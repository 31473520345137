import { Organization } from '@/types/organization';

export interface IUser {
  sessionID: string;
  username: string;
  isEmailUser: boolean;
  hash?: string;
  admin: boolean;
  qa?: boolean;
  specialUploader?: boolean;
  walletAddress?: string;
  advancedReviewer?: boolean;
  loginType?: LoginType;
  reviewerTier?: number;
  apiCredits?: number;
  organizations: Organization[];
  currentOrganization: string | null;
  reviewerWeight?: number;
  trustedReviewer?: boolean;
  email?: string;
  _id?: string;
}

export enum IFleetRole {
  driver = 'driver',
  manager = 'manager',
}

export interface IProfile extends IUser {
  createdAt: string;
  fleetRole: IFleetRole | null;
  fleetForwardingPercentage: number;
}

export interface IUserSettings {
  username: string;
  createdAt: string;
}

export interface ILaunchDarklyUser {
  key?: string;
  name?: string;
  custom: {
    platform: 'ios' | 'android' | 'windows' | 'macos' | 'web';
  };
}

export enum LoginType {
  PHANTOM = 'phantom',
  BREEZE = 'breeze',
}
