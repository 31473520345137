import Router, { useRouter } from 'next/router';
import { useEffect } from 'react';
import { toast } from 'sonner';
import { retrieveBreezeUser } from '@/api/auth';
import { LoginType } from '@/types/user';
import { DEFAULT_CLIENT_PATH } from '@/utils/url';
import * as Toast from '@/components/ui/toast/Toast';
import { useUser } from '@/hooks/useUser';
import { getCurrentOrganization } from '@/utils/organizations';

export default function useBreezeLogin() {
  const { setUser } = useUser();
  const router = useRouter();
  const {
    id: sessionId,
    solanaSignature,
    solanaWalletAddress,
    redirectTo,
  } = router.query;

  useEffect(() => {
    const getBreezeUser = async () => {
      if (
        sessionId &&
        solanaSignature &&
        solanaWalletAddress &&
        typeof sessionId === 'string' &&
        typeof solanaSignature === 'string' &&
        typeof solanaWalletAddress === 'string'
      ) {
        const { user, error } = await retrieveBreezeUser({
          sessionId,
          signature: solanaSignature,
          solanaAddress: solanaWalletAddress,
        });

        if (error || !user) {
          console.log(error);
          toast.custom(t => (
            <Toast.Default
              title="Login Failed"
              description="Resubmit your email or refresh the page and try again."
              buttons={[
                {
                  label: 'Dismiss',
                  onClick: () => toast.dismiss(t),
                  variant: 'secondary',
                  size: 'fw',
                },
                {
                  label: 'Refresh',
                  onClick: () => location.reload(),
                  variant: 'default',
                  size: 'fw',
                },
              ]}
            />
          ));
          return;
        }

        user.loginType = LoginType.BREEZE;

        const cookies = document.cookie;
        const currentOrganization = getCurrentOrganization(user, cookies);
        setUser({
          ...user,
          currentOrganization,
        });

        Router.push((redirectTo as string) ?? DEFAULT_CLIENT_PATH);
      }
    };

    getBreezeUser();
  }, [sessionId, setUser, solanaSignature, solanaWalletAddress, redirectTo]);

  return <></>;
}
