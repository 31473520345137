import { useWallet } from '@solana/wallet-adapter-react';
import { WalletName } from '@solana/wallet-adapter-base';
import { MouseEventHandler, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import bs58 from 'bs58';
import { toast } from 'sonner';
import Image from 'next/image';
import {
  buildQueryParamString,
  DEFAULT_CLIENT_PATH,
  openLinkInNewTab,
  redirectTo as redirectToFn,
} from '@/utils/url';
import { getLoginSession, loginWithPhantom } from '@/api/auth';
import { FeedbackMessage } from '@/utils/phantom';
import { LoginType } from '@/types/user';
import { useUser } from '@/hooks/useUser';
import { Button } from '@/components/shadcn/button';
import * as Toast from '@/components/ui/toast/Toast';
import { getCurrentOrganization } from '@/utils/organizations';

export default function PhantomBrowser() {
  const { publicKey, connected, disconnect, select, signMessage } = useWallet();
  const [toastId, setToastId] = useState<string | number | null>(null);

  const { setUser } = useUser();
  const { query } = useRouter();
  const { redirectTo = '' } = query;

  const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
    select('Phantom' as WalletName);

    if (!window?.solana?.isPhantom) {
      const id = toast.custom(
        () => (
          <Toast.Default
            title="Install Phantom"
            description="Install the Phantom browser extension to continue with Phantom. Once installed, refresh and try again."
            buttons={[
              {
                label: 'Install',
                onClick: () => openLinkInNewTab('https://phantom.app/download'),
                variant: 'secondary',
                size: 'fw',
              },
              {
                label: 'Refresh',
                onClick: () => location.reload(),
                variant: 'default',
                size: 'fw',
              },
            ]}
          />
        ),
        { duration: Infinity },
      );
      setToastId(id);
    }
  };

  useEffect(() => {
    const handleLoginSession = async (publicKey: string) => {
      const session = await getLoginSession();
      const message = new TextEncoder().encode(session);

      if (!signMessage) {
        throw new Error('wallet does not support signning');
      }

      const signature = await signMessage(message);
      const publicKeyAndQuery = buildQueryParamString({
        message: session,
        ...query,
      });

      const user = await loginWithPhantom(
        publicKey,
        bs58.encode(signature),
        publicKeyAndQuery,
      );

      if (user.username) {
        user.loginType = LoginType.PHANTOM;

        const cookies = document.cookie;
        const currentOrganization = getCurrentOrganization(user, cookies);
        setUser({
          ...user,
          currentOrganization,
        });

        redirectToFn((redirectTo as string) || DEFAULT_CLIENT_PATH);
      }
    };

    if (connected && publicKey) {
      handleLoginSession(publicKey.toBase58()).catch(err => {
        const { message } = err;
        console.log(message);
        toast.custom(t => (
          <Toast.Default
            title="Request Failed"
            description={`${FeedbackMessage.FAIL}${message ? `: ${message}` : ''}`}
            buttons={[
              {
                label: 'Dismiss',
                onClick: () => toast.dismiss(t),
                variant: 'secondary',
                size: 'fw',
              },
              {
                label: 'Refresh',
                onClick: () => location.reload(),
                variant: 'default',
                size: 'fw',
              },
            ]}
          />
        ));
        disconnect();
        sessionStorage.clear();
      });
    }
  }, [
    connected,
    disconnect,
    publicKey,
    query,
    redirectTo,
    signMessage,
    setUser,
  ]);

  useEffect(() => {
    return () => {
      if (toastId) {
        toast.dismiss(toastId);
      }
    };
  }, [toastId]);

  return (
    <Button
      variant="secondary"
      disabled={!!toastId}
      size="fw"
      className="w-full justify-start gap-2 text-xs sm:text-base"
      onClick={handleClick}>
      <Image
        src="/images/logos/phantom.svg"
        width={16}
        height={14}
        alt="Phantom logo"
      />
      Link Phantom Account
    </Button>
  );
}
