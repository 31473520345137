import { useEffect } from 'react';
import { useRouter } from 'next/router';
import nacl from 'tweetnacl';
import bs58 from 'bs58';
import { toast } from 'sonner';
import Image from 'next/image';
import { loginWithPhantom, getLoginSession } from '@/api/auth';
import {
  PHANTOM_CLUSTER,
  PHANTOM_CONNECT_LINK,
  HIVEMAPPER_LOGIN_CALLBACK_LINK,
  PHANTOM_CONNECT_METHOD,
  FeedbackMessage,
  extractPublicKeyFromUrlParams,
  IPhantomParams,
} from '@/utils/phantom';
import {
  buildQueryParamString,
  DEFAULT_CLIENT_PATH,
  redirectTo as redirectToFn,
} from '@/utils/url';
import { useUser } from '@/hooks/useUser';
import { Button } from '@/components/shadcn/button';
import * as Toast from '@/components/ui/toast/Toast';
import { getCurrentOrganization } from '@/utils/organizations';

export enum PhantomOriginEnum {
  Login = 'login',
  Connect = 'connect',
}

export default function PhantomDeepLink() {
  const { setUser } = useUser();

  const router = useRouter();
  const { query } = router;
  const {
    phantom_encryption_public_key = null,
    nonce = null,
    data = null,
    errorCode = null,
    errorMessage = null,
    redirectTo = '',
    ...rest
  } = query;

  useEffect(() => {
    if (
      (phantom_encryption_public_key && nonce && data) ||
      (errorCode && errorMessage)
    ) {
      const tryLoginWithPhantom = async () => {
        const { publicKey, session } = errorCode
          ? { publicKey: null, session: null }
          : extractPublicKeyFromUrlParams({
              phantom_encryption_public_key,
              nonce,
              data,
            } as IPhantomParams);
        if (publicKey && session) {
          const message = await getLoginSession();
          const queryParams = buildQueryParamString({
            mobile: 'true',
            message,
            ...rest,
          });

          const user = await loginWithPhantom(publicKey, session, queryParams);

          if (!user || user.error) {
            const message =
              user?.error || 'Something went wrong, please try again later.';
            toast.custom(t => (
              <Toast.Default
                title="Request Failed"
                description={`${FeedbackMessage.FAIL}: ${message}`}
                buttons={[
                  {
                    label: 'Dismiss',
                    onClick: () => toast.dismiss(t),
                    variant: 'secondary',
                    size: 'fw',
                  },
                  {
                    label: 'Refresh',
                    onClick: () => location.reload(),
                    variant: 'default',
                    size: 'fw',
                  },
                ]}
              />
            ));
          }

          if (user.username) {
            user.walletAddress = publicKey;

            const cookies = document.cookie;
            const currentOrganization = getCurrentOrganization(user, cookies);
            setUser({
              ...user,
              currentOrganization,
            });

            redirectToFn((redirectTo as string) || DEFAULT_CLIENT_PATH);
          }
        } else {
          const message =
            errorMessage || 'Something went wrong, please try again later.';
          toast.custom(t => (
            <Toast.Default
              title="Request Failed"
              description={`${FeedbackMessage.FAIL}: ${message}`}
              buttons={[
                {
                  label: 'Dismiss',
                  onClick: () => toast.dismiss(t),
                  variant: 'secondary',
                  size: 'fw',
                },
                {
                  label: 'Refresh',
                  onClick: () => location.reload(),
                  variant: 'default',
                  size: 'fw',
                },
              ]}
            />
          ));
        }
      };

      tryLoginWithPhantom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    const keys = nacl.box.keyPair();
    const publicKey = bs58.encode(keys.publicKey);
    const secretKey = bs58.encode(keys.secretKey);
    localStorage.setItem('temp_phantom_encryption_secret_key', secretKey);

    const buildUrl = (path: string, params: URLSearchParams) =>
      `https://phantom.app/ul/v1/${path}?${params.toString()}`;

    const callbackQuery = buildQueryParamString(rest);

    const params = new URLSearchParams({
      dapp_encryption_public_key: publicKey,
      cluster: PHANTOM_CLUSTER,
      app_url: PHANTOM_CONNECT_LINK,
      redirect_link: `${HIVEMAPPER_LOGIN_CALLBACK_LINK}${callbackQuery}`,
    });

    const url = buildUrl(PHANTOM_CONNECT_METHOD, params);
    redirectToFn(url);
  };

  return (
    <Button
      variant="secondary"
      size="fw"
      className="w-full justify-start gap-2 text-xs sm:text-base"
      onClick={handleClick}>
      <Image
        src="/images/logos/phantom.svg"
        width={16}
        height={14}
        alt="Phantom logo"
      />
      Link Phantom Account
    </Button>
  );
}
