import nacl from 'tweetnacl';
import bs58 from 'bs58';
import { redirectTo as redirectToFn } from '@/utils/url';

export const HIVEMAPPER_LOGIN_CALLBACK_LINK =
  process.env.NODE_ENV === 'development'
    ? `${process.env.NEXT_PUBLIC_LOCAL_HOST}/login`
    : 'https://beemaps.com/login';
export const PHANTOM_CONNECT_LINK = 'https://phantom.app';
export const PHANTOM_CLUSTER = 'mainnet-beta';
export const PHANTOM_CONNECT_METHOD = 'connect';
export const PHANTOM_SIGN_METHOD = 'signMessage';

export enum FeedbackMessage {
  SUCCESS = 'Successfully linked your phantom wallet',
  FAIL = 'Failed to link your phantom wallet',
}

export interface IPhantomParams {
  phantom_encryption_public_key: string;
  nonce: string;
  data: string;
  session: string;
  sharedSecret: Uint8Array;
}

export const extractPublicKeyFromUrlParams = ({
  phantom_encryption_public_key,
  nonce,
  data,
}: IPhantomParams) => {
  const secretKey = localStorage.getItem('temp_phantom_encryption_secret_key');

  if (!secretKey) {
    return { publicKey: null, session: null };
  }

  localStorage.removeItem('temp_phantom_encryption_secret_key');
  const nonceDecoded = bs58.decode(nonce);
  const dataDecode = bs58.decode(data);
  const publicKeyDecoded = bs58.decode(phantom_encryption_public_key);

  const secretKeyDecoded = bs58.decode(secretKey);
  const sharedSecret = nacl.box.before(publicKeyDecoded, secretKeyDecoded);

  const originalMessage = nacl.box.open(
    dataDecode,
    nonceDecoded,
    publicKeyDecoded,
    secretKeyDecoded,
  );

  if (originalMessage) {
    const jsonString = Buffer.from(originalMessage).toString('utf8');
    const parsedData = JSON.parse(jsonString);

    return {
      publicKey: parsedData.public_key,
      session: parsedData.session,
      sharedSecret: sharedSecret,
      appSecretKey: secretKeyDecoded,
    };
  }

  return {
    publicKey: null,
    session: null,
    sharedSecret: undefined,
    appSecretKey: undefined,
  };
};

export const buildUrl = (path: string, params: URLSearchParams) =>
  `https://phantom.app/ul/v1/${path}?${params.toString()}`;

export const connectToPhantom = (
  managerAddress: string,
  memberAddress: string,
  message: string,
  memberName: string,
  ownerMemberName: string,
  organization: string,
) => {
  const keys = nacl.box.keyPair();
  const dappPublicKey = bs58.encode(keys.publicKey);
  const secretKey = bs58.encode(keys.secretKey);
  localStorage.setItem('temp_phantom_encryption_secret_key', secretKey);

  const params = new URLSearchParams({
    dapp_encryption_public_key: dappPublicKey,
    cluster: PHANTOM_CLUSTER,
    app_url: PHANTOM_CONNECT_LINK,
    redirect_link: `${document.location.origin}/join/${managerAddress}/${memberAddress}?message=${message}&memberName=${memberName}&ownerMemberName=${ownerMemberName}&organization=${organization}`,
  });
  const url = buildUrl(PHANTOM_CONNECT_METHOD, params);
  redirectToFn(url);
};

export const phantomSignMessage = (
  managerAddress: string,
  memberAddress: string,
  session: string,
  sharedSecret: Uint8Array,
  appSecretKey: Uint8Array,
  message: string,
  memberName: string,
  ownerMemberName: string,
  organization: string,
) => {
  const messageData = new TextEncoder().encode(message);
  const payload = {
    session,
    message: bs58.encode(messageData),
  };
  const [nonce, encryptedPayload] = encryptPayload(payload, sharedSecret);

  const dappPublicKey = nacl.box.keyPair.fromSecretKey(appSecretKey).publicKey;

  const params = new URLSearchParams({
    dapp_encryption_public_key: bs58.encode(dappPublicKey),
    nonce: bs58.encode(nonce),
    redirect_link: `${document.location.origin}/join/${managerAddress}/${memberAddress}?message=${message}&validate_signature=true&memberName=${memberName}&ownerMemberName=${ownerMemberName}&organization=${organization}`,
    payload: bs58.encode(encryptedPayload),
  });
  const url = buildUrl(PHANTOM_SIGN_METHOD, params);
  redirectToFn(url);
};

export const decryptPayload = (
  data: string,
  nonce: string,
  sharedSecret?: Uint8Array,
) => {
  if (!sharedSecret) {
    throw new Error('missing shared secret');
  }
  let decryptedData: Uint8Array | boolean | null;

  try {
    decryptedData = nacl.box.open.after(
      bs58.decode(data),
      bs58.decode(nonce),
      sharedSecret,
    );
  } catch (error: any) {
    throw new Error(`Unable to decode data ${error}`);
  }

  if (!decryptedData) {
    throw new Error('Unable to decrypt data');
  }
  return JSON.parse(Buffer.from(decryptedData).toString('utf8'));
};

const encryptPayload = (payload: any, sharedSecret?: Uint8Array) => {
  if (!sharedSecret) {
    throw new Error('missing shared secret');
  }

  const nonce = nacl.randomBytes(24);

  const encryptedPayload = nacl.box.after(
    new Uint8Array(Buffer.from(JSON.stringify(payload))),
    nonce,
    sharedSecret,
  );

  return [nonce, encryptedPayload];
};
