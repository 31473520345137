import { Dispatch, SetStateAction, useState } from 'react';
import { useRouter } from 'next/router';
import { toast } from 'sonner';
import { Input } from '@/components/shadcn/input';
import { Button } from '@/components/shadcn/button';
import { validateEmail } from '@/utils/form';
import { postToBreeze } from '@/api/auth';
import * as Toast from '@/components/ui/toast/Toast';
import Error from '@/components/ui/errors/Error';

export default function BreezeEmailForm({
  setIsEmailForm,
  redirectionPath,
}: {
  setIsEmailForm: Dispatch<SetStateAction<boolean>>;
  redirectionPath?: string;
}) {
  const [emailAddress, setEmailAddress] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);

  const router = useRouter();
  const {
    query: { redirectTo = '' },
  } = router;

  const submitEmailToBreeze = async (email: string) => {
    if (validateEmail(email)) {
      setIsEmailValid(true);
      const response = await postToBreeze(
        email,
        redirectionPath,
        redirectTo as string,
      );

      if (response?.url) {
        router.push(response?.url);
      }

      if ('error' in response) {
        setEmailAddress('');
        toast.custom(t => (
          <Toast.Default
            title="Request Failed"
            description="Resubmit your email or refresh the page and try again."
            buttons={[
              {
                label: 'Dismiss',
                onClick: () => toast.dismiss(t),
                variant: 'secondary',
                size: 'fw',
              },
              {
                label: 'Refresh',
                onClick: () => location.reload(),
                variant: 'default',
                size: 'fw',
              },
            ]}
          />
        ));
      }
    } else {
      setIsEmailValid(false);
    }
  };

  return (
    <>
      <div className="flex w-full text-center text-xs sm:text-base">
        Bee Maps has partnered with Breeze to help contributors establish a
        blockchain wallet using only an email address.
      </div>
      <Input
        type="email"
        placeholder="email@address.com"
        className="border-neutral-700 bg-neutral-1000 text-xs text-neutral-400 placeholder:text-neutral-700 focus-visible:ring-neutral-700 sm:text-base"
        value={emailAddress}
        autoFocus
        onKeyDown={e => {
          if (e.key === 'Enter') {
            submitEmailToBreeze(emailAddress);
          }
        }}
        onChange={e => {
          setEmailAddress(e.target.value);
          setIsEmailValid(true);
        }}
      />
      <Error visible={!isEmailValid}>Please enter a valid email address</Error>
      <div className="flex w-full justify-center gap-4 text-xs sm:text-base">
        <Button
          variant="secondary"
          className="text-xs sm:text-base"
          onClick={() => setIsEmailForm(false)}>
          Cancel
        </Button>
        <Button
          variant="default"
          className="text-xs sm:text-base"
          onClick={() => {
            submitEmailToBreeze(emailAddress);
          }}>
          Confirm
        </Button>
      </div>
    </>
  );
}
