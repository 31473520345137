import Link from 'next/link';
import React from 'react';

export default function LoginFooter() {
  return (
    <div className="text-center text-xs sm:text-base">
      <span>
        By using our website, you agree to our
        <br />
      </span>
      <Link href="/tos" className="underline">
        Terms of Service
      </Link>
      <span>{` and `}</span>
      <Link href="/privacy" className="underline">
        Privacy Policy
      </Link>
      <span>.</span>
    </div>
  );
}
